import(/* webpackMode: "eager" */ "/vercel/path0/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/components/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieConsentProvider"] */ "/vercel/path0/context/CookieConsentContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavProvider"] */ "/vercel/path0/context/NavContext.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/context/PWAContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Blinker\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"blinker\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/fonts.ts\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"weight\":[\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"sourceSans3\"}");
