'use client';
import { CookieIcon } from 'lucide-react';
import { Button } from './ui/button';
import { cn } from '@/lib/utils';
import { useState, useEffect } from 'react';
import { useCookieConsent } from '@/context/CookieConsentContext';
import Link from 'next/link';
export default function CookieConsent({
  demo = false,
  onAcceptCallback = () => {},
  onDeclineCallback = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [hide, setHide] = useState(false);
  const { giveConsent, revokeConsent } = useCookieConsent();

  const accept = () => {
    setIsOpen(false);
    giveConsent();
    setTimeout(() => {
      setHide(true);
    }, 700);
    onAcceptCallback();

    // Déclencher l'événement personnalisé pour activer Hotjar
    window.dispatchEvent(new Event('activateHotjar'));
  };

  const decline = () => {
    setIsOpen(false);
    revokeConsent();
    setTimeout(() => {
      setHide(true);
    }, 700);
    onDeclineCallback();
  };

  useEffect(() => {
    const cookieConsent = document.cookie.includes('cookieConsent=true');
    if (cookieConsent === true && !demo) {
      setIsOpen(false);
      setTimeout(() => {
        setHide(true);
      }, 700);
    } else {
      setIsOpen(true);
    }
  }, [demo]);

  return (
    <div
      className={cn(
        'fixed z-[200] bottom-0 right-0 w-full transition-transform duration-700 md:max-w-md',
        !isOpen
          ? 'transition-[opacity,transform] translate-y-8 opacity-0'
          : 'transition-[opacity,transform] translate-y-0 opacity-100',
        hide && 'hidden'
      )}
    >
      <div className="m-2 rounded-lg border bg-background">
        <div className="grid gap-2">
          <div className="flex h-14 items-center justify-between border-b border-border p-4">
            <h3 className="text-lg font-medium">Le site utilise des cookies</h3>
            <CookieIcon className="size-6" />
          </div>
          <div className="p-4">
            <p className="text-sm font-normal">
              Le site utilise des cookies pour améliorer votre expérience. Ces
              cookies aident à comprendre comment vous interagissez avec le
              contenu et à optimiser le site pour vous offrir une navigation
              fluide et personnalisée.
              <br />
              <br />
              <span className="text-xs">
                En cliquant sur
                <span className="font-medium opacity-80"> Accepter</span>, vous
                acceptez l&apos;utilisation des cookies.
              </span>
              <br />
              <Link href="/legal/cookies" className="text-xs underline">
                En savoir plus.
              </Link>
            </p>
          </div>
          <div className="flex gap-2 border-t border-border bg-background/20 p-4 py-5">
            <Button
              onClick={decline}
              className="w-full"
              variant="primaryOutline"
            >
              Refuser
            </Button>{' '}
            <Button onClick={accept} className="w-full" variant="primary">
              Accepter
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
